<template>
    <div class="wrap">
        <v-Title title="公司专利" content="以科技创新为主导"></v-Title>
        <div class="content">
            <img src="../assets/patent.png" alt />
            <span>以科技创新为主导，公司已经取得了计算机软件著作权15项。申请发明专利，实用新型专利和外观专利多项。</span>
        </div>
    </div>
</template>
<script>
import Title from './Title'
export default {
    components: {
        'v-Title': Title
    }
}
</script>
<style lang="scss" scoped>
.wrap {
    max-width: 1024px;
    margin: 0 auto;
    .content {
        width: 100%;
        display: flex;
        justify-items: center;
        flex-direction: column;
        align-items: center;
        span {
            font-size: 18px;
            font-weight: 400;
            color: rgba(153, 153, 153, 1);
            margin-top: 16px;
        }
    }
}
</style>