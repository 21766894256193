<template>
    <div>
        <v-Header></v-Header>
        <v-Tel></v-Tel>
        <v-Top v-show="side" class="animated fadeInUp" @click.native="goTop"></v-Top>
        <div class="about" ref="about">
            <div>
                <div ref="banner">
                    <v-Banner :bannerObj="bannerObj"></v-Banner>
                </div>
                <v-Introduction></v-Introduction>
                <v-Patent></v-Patent>
                <v-Bottom></v-Bottom>
            </div>
        </div>
    </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Banner from '@/components/Banner.vue'
import Introduction from '@/components/Introduction.vue'
import Patent from '@/components/Patent.vue'
import Bottom from '@/components/Bottom.vue'
import BScroll from 'better-scroll'
import Tel from '@/components/Tel.vue'
import Top from '@/components/Top.vue'
export default {
    name: 'home',
    data() {
        return {
            side: false,
            bannerObj: {
                title: '关于我们',
                desc: '科技助力救援 创新服务应急',
                culture: '让消防官兵少流血少流泪',
                img: require('@/assets/banner_02.png')
            }
        }
    },
    components: {
        'v-Header': Header,
        'v-Banner': Banner,
        'v-Introduction': Introduction,
        'v-Patent': Patent,
        'v-Bottom': Bottom,
        'v-Tel': Tel,
        'v-Top': Top
    },
    methods: {
        goTop() {
            this.listScroll.scrollTo(0, 0, 200)
        },
        initScroll() {
            this.$nextTick(() => {
                if (!this.$refs.about) {
                    return
                }
                //配置: 可根据个人需求
                this.listScroll = new BScroll(this.$refs.about, {
                    probeType: 1,
                    scrollY: true,
                    click: true,
                    useTransition: false, // 防止iphone微信滑动卡顿
                    bounce: true,
                    momentumLimitDistance: 5,
                    mouseWheel: {
                        speed: 20,
                        invert: false,
                        easeTime: 0
                    }
                })
                this.listScroll.on('scroll', pos => {
                    let banner = this.$refs.banner.clientHeight
                    // 使用abs绝对值（否则 pos.y拿到值是负数）
                    this.scrollY = Math.abs(Math.round(pos.y))
                    //判断滑动距离大于"商品介绍"元素时, 吸顶title,否则隐藏
                    if (this.scrollY > banner) {
                        this.side = true
                    } else {
                        this.side = false
                    }
                })
            })
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.initScroll()
        })
    }
}
</script>
<style lang="scss" scoped>
.about {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
}
</style>
